import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HeaderBar, MachineBody, MachineButton } from "../components/common";
import { registerButton } from "../static";

function Default() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <main className="default">
      <HeaderBar>
        <div className="default_header">
          <h2 className="default_header_h2">Are You Lucky Enough</h2>
          <h1 className="default_header_h1">To Win Prizes?</h1>
        </div>
      </HeaderBar>
      <MachineBody>
        <div style={{ display: "flex", alignItems: "center" }} className="profile_body earn_body">
          <ul className="earnpage-rewardsList defaultPage">
            <li
              style={{
                background: "none",
                width: "14.125rem",
                height: "21.0625rem",
                border: "0",
              }}
              className="earnpage-rewardsListItem"
            >
              <span
                style={{ width: "14.125rem", height: "21.0625rem" }}
                className="rewardsListItem-rewardImage"
              >
                <img
                  style={{ width: "14.125rem", height: "21.0625rem" }}
                  src="/defaultImage1.png"
                  alt="prize"
                />
              </span>
            </li>
            <li
              style={{
                background: "none",
                width: "14.125rem",
                height: "21.0625rem",
                border: "0",
              }}
              className="earnpage-rewardsListItem"
            >
              <span
                style={{ width: "14.125rem", height: "21.0625rem" }}
                className="rewardsListItem-rewardImage"
              >
                <img
                  style={{ width: "14.125rem", height: "21.0625rem" }}
                  src="/defaultImage2.png"
                  alt="prize"
                />
              </span>
            </li>
            <li
              style={{
                background: "none",
                width: "14.125rem",
                height: "21.0625rem",
                border: "0",
              }}
              className="earnpage-rewardsListItem"
            >
              <span
                style={{ width: "14.125rem", height: "21.0625rem" }}
                className="rewardsListItem-rewardImage"
              >
                <img
                  style={{ width: "14.125rem", height: "21.0625rem" }}
                  src="/defaultImage3.png"
                  alt="prize"
                />
              </span>
            </li>
          </ul>
        </div>
      </MachineBody>
      <MachineButton>
        <div className="default_actionButton">
          <Link to="/register" className="default_actionButton_register">
            <img src={registerButton} alt="Register_Now" />
          </Link>
          <Link to="/login" className="default_actionButton_login">
            Or login to your account here
          </Link>
        </div>
      </MachineButton>
    </main>
  );
}

export default Default;
