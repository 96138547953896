const entryPoint = "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789";
const factory = "0x2bf9454c08bb13dc52a90ec2702454bdd39a0a32";
const AlchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const rpcUrl = `https://polygon-mainnet.g.alchemy.com/v2/${AlchemyKey}`;
const fixedNonce = 3;
const LyncNFTAddress = "0xfBDd7B207b778FD7Bfa5cB32fa9A12Ba5bE0A074";
const ImplementationContractAddress =
  "0x264478e9aeb659c877dc6df748409d6a44f4793b";

const Rewards = "0x1629179ec4e32F81E94688c608f6eC48BA949e84";
const explorerURL = "https://polygonscan.com/address/";

const ChainId = "137";
const minRequiredToRedeem = [150, 180, 200];
// Todo change this object 
const couponIdOBj={
  150:1,
  180:2,
  200:3
}
export const CONFIG = {
  entryPoint,
  factory,
  AlchemyKey,
  rpcUrl,
  fixedNonce,
  ImplementationContractAddress,
  explorerURL,
  LyncNFTAddress,
  Rewards,
  ChainId,
  minRequiredToRedeem,
  couponIdOBj
};
