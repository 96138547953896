import { ethers } from "ethers";
import { CONFIG } from "./config";
import Rewards_ABI from "../abi/Rewards.json";

async function fetchGemsAndTokens(tokenID) {
  try {
    const provider = ethers.getDefaultProvider(CONFIG.rpcUrl);
    const rewardContract = new ethers.Contract(
      CONFIG.Rewards,
      Rewards_ABI,
      provider
    );
    const lotteryPlayer = await rewardContract.getLotteryPlayer(tokenID);
    return { lotteryPlayer, provider, rewardContract };
  } catch (e) {
    // console.log("Error in fetchGemsAndTokens");
    console.log(e);
  }
}
export default fetchGemsAndTokens;
