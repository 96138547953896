import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { client, parsers } from "@passwordless-id/webauthn";
// import { AccentButton, Heading, SubHeading } from "../components/ui";
import { encryptAPIKey } from "../Helper/encryptMessage";
import { ApiRoutes } from "../Helper/ApiRoutes";
import ApiCall from "../Helper/ApiCall";
import { HeaderBar, MachineBody, MachineButton } from "../components/common";
import { generatePassButton, headerStars, registerBanner } from "../static";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
  });
  const [loading, setLoading] = useState(false);
  function handleChange(e) {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.id]: e.target.value,
      };
    });
  }
  const handleDigits = (event) => {
    const inputElement = event.target;
    const currentValue = inputElement.value;

    if (currentValue.length > 10) {
      inputElement.value = currentValue.toString().slice(0, -1);
    }
  };
  async function handleSubmit(e) {
    e.preventDefault();
    if (!client.isAvailable()) {
      toast.error("WebAuthn is not supported in this browser");
      return;
    }
    // console.log(formData);
    const { fullName, email, contactNumber } = formData;
    if (!fullName || !email || !contactNumber) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    try {
      const userRegister = await ApiCall(ApiRoutes.userRegister, "POST", {
        email,
        name: fullName,
        contact: contactNumber,
      });
      if (userRegister.res.ok) {
        const {
          data: { challenge, userHandle, ticketId },
        } = userRegister.resData;
        // console.log(challenge, userHandle);
        const registrationOptions = {
          authenticatorType: "both",
          userVerification: "required",
          timeout: 120000,
          attestation: false,
          userHandle: userHandle, // Review later - can be useful for emails, etc.
          debug: false,
        };
        const registration = await client.register(
          email, // @arpit - using email as username - fix this
          challenge,
          registrationOptions
        );
        // console.log(registration);
        const x = parsers.parseRegistration(registration);
        // console.log("parsers==>>>", x);
        if (registration) {
          const userVerificationRegistration = await ApiCall(
            ApiRoutes.userVerifyRegistration,
            "POST",
            { registration, ticketId }
          );

          if (userVerificationRegistration.res.ok) {
            toast.success("Successfully Register");

            setTimeout(() => {
              navigate("/generate-pass", {
                state: {
                  email: email,
                  credentialId:
                    userVerificationRegistration.resData?.data?.registrationParsed?.credential?.id,
                  ticketId: ticketId,
                  name: fullName,
                },
              });
            }, 1500);
          } else {
            toast.error(userVerificationRegistration.resData.message);
          }
        } else {
          toast.error("Registration Failed");
          return;
        }
      } else {
        toast.error(userRegister.resData.message);
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error("Registration Failed");
    } finally {
      setLoading(false);
    }
  }
  return (
    <main className="register">
      <HeaderBar>
        <h1 className="register_header">
          Register Now!
          <img src={headerStars} alt="stars" className="register_header_stars" />
        </h1>
      </HeaderBar>
      <MachineBody>
        <div className="register_body">
          <div className="register_body_banner">
            <span className="register_body_banner_bannerImg">
              <img src={registerBanner} alt="banner" />
            </span>
            <div className="register_body_banner_bannerText">
              <h2 className="register_body_banner_bannerText_h2">
                Elevate Your Experience:
                <br />
                Join Symbitech, Earn Rewards.
                <img
                  src={headerStars}
                  alt="stars"
                  className="register_body_banner_bannerText_h2_stars"
                />
              </h2>
            </div>
          </div>
          <form className="register_body_form" onSubmit={(e) => handleSubmit(e)}>
            <div className="register_body_form_controlGroup">
              <span className="register_body_form_controlGroup_icon">
                <img style={{ width: "12px", height: "14px" }} src="/profile.svg" alt="icon" />
              </span>
              <input
                type="text"
                name="fullName"
                id="fullName"
                value={formData.fullName || ""}
                onChange={(e) => handleChange(e)}
                required={true}
                placeholder="Full Name"
                className="register_body_form_controlGroup_control"
              />
            </div>
            <div className="register_body_form_controlGroup">
              <span className="register_body_form_controlGroup_icon">
                <img style={{ width: "14px", height: "11px" }} src="/message.svg" alt="icon" />
              </span>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email || ""}
                onChange={(e) => handleChange(e)}
                required={true}
                placeholder="abc@email.com"
                className="register_body_form_controlGroup_control"
              />
            </div>
            <div className="register_body_form_controlGroup">
              <span className="register_body_form_controlGroup_icon">
                <img style={{ width: "14px", height: "14px" }} src="/phone.svg" alt="icon" />
              </span>
              <input
                type="number"
                name="contactNumber"
                id="contactNumber"
                pattern="[0-9]{10}"
                onInput={handleDigits}
                maxLength={10}
                minLength={10}
                value={formData.contactNumber || ""}
                onChange={(e) => handleChange(e)}
                required={true}
                onWheel={(e) => e.target.blur()}
                placeholder="Your Contact Number"
                className="register_body_form_controlGroup_control"
              />
            </div>
          </form>
        </div>
      </MachineBody>
      <MachineButton>
        <div className="register_actionButton">
          <button
            disabled={loading}
            onClick={(e) => handleSubmit(e)}
            className="register_actionButton_registerButton"
          >
            <img
              src={loading ? "/generatePassDisabled.png" : generatePassButton}
              alt="Generate_Your_Pass"
            />
          </button>
          <Link to="/login" className="register_actionButton_loginButton">
            Or login to your account here
          </Link>
        </div>
      </MachineButton>
    </main>
  );
}

export default Register;
