import { CONFIG } from "./config";
import FactoryAbi from "../abi/Factory.json";
import getXandY from "./getPubXandY";
import { ethers } from "ethers";
import { encryptAPIKey } from "./encryptMessage";
import ApiCall from "./ApiCall";
import { ApiRoutes } from "./ApiRoutes";

async function getSmartWalletAddress(email) {
  const provider = new ethers.getDefaultProvider(CONFIG.rpcUrl);
  const FactoryContract = new ethers.Contract(
    CONFIG.factory,
    FactoryAbi,
    provider
  );
  try {
    const userDetails = await ApiCall(ApiRoutes.userGetUserDetails, "POST", {
      email,
    });

    if (userDetails.res.ok && userDetails.resData.success) {
      const { x, y } = await getXandY(userDetails.resData?.data?.publicKey);
      // console.log(x, y);
      const smartWalletAddress = await FactoryContract.getAddress(
        CONFIG.fixedNonce,
        userDetails.resData?.data?.keyId,
        x,
        y
      );
      // console.log(smartWalletAddress);
      return {
        provider,
        factory: FactoryContract,
        smartWalletAddress,
        publicKey: userDetails.resData?.data?.publicKey,
        keyId: userDetails.resData?.data?.keyId,
        publicX: x,
        publicY: y,
      };
    } else {
      throw new Error("Please try again!!");
    }
  } catch (e) {
    throw new Error("Please try again!!");
  }
}
export default getSmartWalletAddress;
