import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import fetchGemsAndTokens from "../Helper/FetchGemsAndTokens";
import ApiCall from "../Helper/ApiCall";
import { ApiRoutes } from "../Helper/ApiRoutes";
import { BottomNavbar } from "../components/shared/BottomNavbar";
import { HeaderShort, MachineBody } from "../components/common";
import { CoinBalance } from "../components/shared/CoinBalance";
import { MachineBodyLong } from "../components/common/MachineBody";
import { CONFIG } from "../Helper/config";
import toast from "react-hot-toast";
import passLessVerification from "../Helper/passLessVerification";
import getSmartWalletAddress from "../Helper/GetSmartWalletAddress";
import getEncodedSignature from "../Helper/getEncodedSignatre";
import { ethers } from "ethers";
import getRendomNonce from "../Helper/getRendomNonce";
import ACCOUNT_ABI from "../abi/PassKeyAccount.json";
import Rewards_ABI from "../abi/Rewards.json";
import { encryptAPIKey } from "../Helper/encryptMessage";
import { Spinner } from "../components/common/Spinner";

export default function Rewards() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isRedeemingCoupon, setRedeemingCoupon] = useState(false);
  const [redeemingId, setRedeemingId] = useState(undefined);

  const [gems, setGems] = useState(null);
  const state = location.state;
  // console.log(state);

  async function fetchGems() {
    const { resData } = await ApiCall(ApiRoutes.getGemsDetails, "POST", {
      email: state.email,
      tokenId: state.tokenId,
    });
    setGems(resData.data.gems);
    setLoading(false);
  }

  async function reward(couponId) {
    try {
      setRedeemingCoupon(true);
      setRedeemingId(couponId);
      const minGemsReqired = CONFIG.minRequiredToRedeem[couponId];
      if (Number(gems) < Number(minGemsReqired)) {
        toast.error("Not enough Gems to Redeem");
        return;
      }
      const { signature, authenticatorData, challenge, client, clientData } =
        await passLessVerification(state.email);
      const smartWalletData = await getSmartWalletAddress(state?.email);
      const encodedSig = await getEncodedSignature(
        signature,
        authenticatorData,
        challenge,
        smartWalletData.keyId,
        client,
        clientData
      );
      const initCode = "0x";
      const provider = smartWalletData.provider;
      const coupenNonce = getRendomNonce();
      const signingAccount = new ethers.Wallet(process.env.REACT_APP_PVT_KEY);
      const rewardContract = new ethers.Contract(
        CONFIG.Rewards,
        Rewards_ABI,
        provider
      );
      // console.log(coupenNonce, signingAccount);

      const encodedData = ethers.utils.solidityPack(
        ["address", "uint256", "uint256"],
        [CONFIG.Rewards, CONFIG.ChainId, coupenNonce]
      );
      const hash = ethers.utils.keccak256(encodedData);
      // console.log(hash);
      const rewardsSign = await signingAccount.signMessage(
        ethers.utils.arrayify(hash)
      );

      const rewardNFTData = rewardContract.interface.encodeFunctionData(
        "redeemCoupon",
        [state.tokenId, Number(couponId) + 1, rewardsSign, coupenNonce]
      );

      // console.log(minGemsReqired, rewardContract, rewardNFTData);
      const AccountContract = new ethers.Contract(
        state.smartAccountAddress,
        ACCOUNT_ABI,
        provider
      );
      const encodedCall = AccountContract.interface.encodeFunctionData(
        "execute",
        [CONFIG.Rewards, ethers.BigNumber.from(0), rewardNFTData]
      );
      const currentNonce = await AccountContract.getNonce();
      // console.log(currentNonce);
      const optionsPaymaster = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          id: 1,
          jsonrpc: "2.0",
          method: "alchemy_requestGasAndPaymasterAndData",
          params: [
            {
              policyId: process.env.REACT_APP_ALCHEMY_POLICY_ID,
              entryPoint: CONFIG.entryPoint,
              dummySignature: encodedSig,
              userOperation: {
                sender: state.smartAccountAddress,
                nonce: currentNonce._hex,
                initCode: initCode,
                callData: encodedCall,
              },
            },
          ],
        }),
      };
      const respaymaster = await fetch(CONFIG.rpcUrl, optionsPaymaster);
      // console.log(respaymaster);
      if (respaymaster.ok) {
        const respaymasterData = await respaymaster.json();
        if (!respaymasterData.error) {
          // console.log(respaymaster);
          // console.log("respaymasterData: ", respaymasterData);
          let op = {
            sender: state.smartAccountAddress,
            nonce: currentNonce._hex,
            initCode: initCode,
            callData: encodedCall,
            maxFeePerGas: respaymasterData.result.maxFeePerGas,
            maxPriorityFeePerGas: respaymasterData.result.maxPriorityFeePerGas,
            signature: encodedSig,
          };

          op.callGasLimit = respaymasterData.result.callGasLimit;
          op.verificationGasLimit =
            respaymasterData.result.verificationGasLimit;
          op.preVerificationGas = respaymasterData.result.preVerificationGas;
          op.paymasterAndData = respaymasterData.result.paymasterAndData;
          // console.log("op: ", op);
          const options1 = {
            method: "POST",
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              id: 1,
              jsonrpc: "2.0",
              method: "eth_sendUserOperation",
              params: [op, CONFIG.entryPoint],
            }),
          };

          const alachemyresp = await fetch(CONFIG.rpcUrl, options1);
          const alachemyrespData = await alachemyresp.json();
          // console.log(alachemyrespData);
          if (alachemyrespData.result && alachemyresp.ok) {
            // console.log(minGemsReqired, "asdfffffff");
            const decreaseGems = await encryptAPIKey(minGemsReqired);
            const updateCount = await ApiCall(ApiRoutes.decreaseGems, "POST", {
              email: state.email,
              gems: decreaseGems,
            });
            if (updateCount.res.ok) {
              setGems((prv) => Number(prv) - Number(minGemsReqired));
              toast.success("Redeemed Successfully");
            }
          }
          // console.log("alachemyrespData: ", alachemyrespData);
        } else {
          // console.log(respaymasterData);
          // console.log("Error in Alchemy Gas Estimation");
          toast.error("Please try again!!");
        }
      } else {
        // console.log("Error in Alchemy Gas Estimation");
        toast.error("Please try again!!");
      }
    } catch (err) {
      // toast.error("Error in Redeeming");
      console.log(err);
    } finally {
      setRedeemingCoupon(false);
    }
  }

  useEffect(() => {
    const isLogedIn = localStorage.getItem("login");
    if (state && state?.smartAccountAddress && isLogedIn) {
      fetchGems();
    } else {
      navigate("/", { replace: true });
      return;
    }
  }, [state]);
  return (
    <>
      {state && !loading ? (
        <>
          <main className="profile earn">
            <HeaderShort>
              <CoinBalance coins="100000" gems={gems} />
            </HeaderShort>
            <MachineBodyLong>
              <div className="profile_body earn_body">
                <ul className="rewardspage-rewardsList">
                  <li className="rewardspage-rewardsListItem">
                    <span className="rewardsListItem-rewardImage">
                      <img src="/reward1.png" alt="reward" />
                    </span>
                    <div>
                      <button
                        className={`rewardsListItem-rewardValue ${
                          gems < 150 || isRedeemingCoupon ? "disabled" : ""
                        }`}
                        disabled={gems < 150 || isRedeemingCoupon}
                        onClick={() => reward(0)}
                      >
                        Redeem <img src="/mainDiamond.svg" alt="diamond" />{" "}
                        <span style={{ color: "#FF7B05" }}>150</span>
                      </button>
                    </div>
                    {isRedeemingCoupon && redeemingId === 0 ? (
                      <LocalSpinner />
                    ) : null}
                  </li>
                  <li className="rewardspage-rewardsListItem">
                    <span className="rewardsListItem-rewardImage">
                      <img src="/reward2.png" alt="reward" />
                    </span>
                    <div>
                      <button
                        className={`rewardsListItem-rewardValue ${
                          gems < 180 || isRedeemingCoupon ? "disabled" : ""
                        }`}
                        disabled={gems < 180 || isRedeemingCoupon}
                        onClick={() => reward(1)}
                      >
                        Redeem <img src="/mainDiamond.svg" alt="diamond" />{" "}
                        <span style={{ color: "#FF7B05" }}>180</span>
                      </button>
                    </div>
                    {isRedeemingCoupon && redeemingId === 1 ? (
                      <LocalSpinner />
                    ) : null}
                  </li>
                  <li className="rewardspage-rewardsListItem">
                    <span className="rewardsListItem-rewardImage">
                      <img src="/reward3.png" alt="reward" />
                    </span>
                    <div>
                      <button
                        className={`rewardsListItem-rewardValue ${
                          gems < 200 || isRedeemingCoupon ? "disabled" : ""
                        }`}
                        disabled={gems < 200 || isRedeemingCoupon}
                        onClick={() => reward(2)}
                      >
                        Redeem <img src="/mainDiamond.svg" alt="diamond" />{" "}
                        <span style={{ color: "#FF7B05" }}>200</span>
                      </button>
                    </div>
                    {isRedeemingCoupon && redeemingId === 2 ? (
                      <LocalSpinner />
                    ) : null}
                  </li>
                </ul>
              </div>
            </MachineBodyLong>
            <div style={{ height: "3.25rem" }} className=""></div>
          </main>
          <BottomNavbar activeId={1} state={state} />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

const LocalSpinner = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        backdropFilter: "blur(3px)",
        background: "rgba(0, 0, 0, 0.25)",
      }}
      className="appSpinner"
    >
      <span class="appSpinner-spinner"></span>
    </div>
  );
};
