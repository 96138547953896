import React, { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getSmartWalletAddress from "../Helper/GetSmartWalletAddress";
import toast from "react-hot-toast";
import { initialState, reducer } from "../Helper/Reducer";
import deplySmartAccount from "../Helper/DeployeSmartAccount";
import { HeaderBar, MachineBody, MachineButton } from "../components/common";
import { generatePassButton, headerStars, passBG } from "../static";
import { CONFIG } from "../Helper/config";

function GeneratePass() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state;

  const [data, dispatch] = useReducer(reducer, initialState);
  // console.log(data);

  // console.log(state);
  async function fetchData() {
    try {
      const smartWalletData = await getSmartWalletAddress(state?.email);
      if (smartWalletData) {
        dispatch({ type: "SET_DATA", payload: { ...smartWalletData } });
      }
    } catch (e) {
      // console.log(e);
      toast.error(e.message);
    }
  }

  useEffect(() => {
    if (state) {
      fetchData();
    } else {
      return navigate("/");
    }
  }, [state]);

  return (
    <main className="generatePass">
      <HeaderBar>
        <h1 className="generatePass_header">
          Let&apos;s unlock blockchain
          <img src={headerStars} alt="stars" className="generatePass_header_stars" />
        </h1>
      </HeaderBar>
      <MachineBody>
        <div className="generatePass_passContainer">
          <div className="generatePass_passContainer_passBg">
            <img src={passBG} alt="pass" />
          </div>
          <div className="generatePass_passContainer_passTitle">
            SymbiTech 2024
            <br />
            Your NFT Ticket
          </div>
          <div className="generatePass_passContainer_passBody firstOfType">
            <div>
              <span className="generatePass_passContainer_passBody_label">Name</span>
              <p className="generatePass_passContainer_passBody_value">
                {state?.name || "Your Name"}
              </p>
            </div>
            <div>
              <span className="generatePass_passContainer_passBody_label">Ticket ID</span>
              <p className="generatePass_passContainer_passBody_value">
                {`#${state?.ticketId || "0001"}`}
              </p>
            </div>
          </div>
          <div className="generatePass_passContainer_passBody secondOfType">
            <span className="generatePass_passContainer_passBody_label">Date</span>
            <p className="generatePass_passContainer_passBody_value">20th & 21st September</p>
          </div>
          <div className="generatePass_passContainer_passBody thirdOfType">
            <span className="generatePass_passContainer_passBody_label">Time</span>
            <p className="generatePass_passContainer_passBody_value">09:30 AM to 04:00 PM</p>
          </div>
          <div className="generatePass_passContainer_passBody firstOfType">
            <div>
              <span className="generatePass_passContainer_passBody_label">Smart Account</span>
              <p className="generatePass_passContainer_passBody_value">
                {data?.smartWalletAddress ? (
                  <a href={`${CONFIG.explorerURL}${data?.smartWalletAddress}`} target="_blank">
                    {data?.smartWalletAddress}
                  </a>
                ) : (
                  "Generating..."
                )}
              </p>
            </div>
          </div>
          <div className="generatePass_passContainer_passBody_sponsor">
            Powered by <img src="/lyncWithText.svg" alt="lync" />
          </div>
        </div>
      </MachineBody>
      <MachineButton>
        <div className="generatePass_actionButton">
          <button
            disabled={data.isLoading || data.isDeploying}
            onClick={() => deplySmartAccount(data, dispatch, state.email, navigate)}
            className="generatePass_actionButton_generateButton"
          >
            <img
              src={
                data.isLoading || data.isDeploying
                  ? "/minPassButtonDisabled.png"
                  : "/minPassButton.png"
              }
              alt="Mint_Pass"
            />
          </button>
        </div>
      </MachineButton>
    </main>
  );
}

export default GeneratePass;
