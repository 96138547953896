import crypto from "crypto-browserify";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
const iv = crypto.randomBytes(16);

export const encryptAPIKey = async (message = "") => {
  message = message ? message : process.env.REACT_APP_API_KEY;
  const time = Date.now();
  let cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(encryptionKey),
    iv
  );

  const originalText = `${message}$$${time}`;
  let encryptedText = cipher.update(originalText);

  encryptedText = Buffer.concat([encryptedText, cipher.final()]);

  return `${encryptedText.toString("hex")}**${iv.toString("hex")}`;
};

// Decrypting text
export function decrypt(message) {
  const encryptedHash = message.split("**");
  const iV = encryptedHash[1];
  const data = encryptedHash[0];
  const text = { iV, encryptedData: data };
  const key = process.env.REACT_APP_ENCRYPTION_KEY; // need to be 32 bits
  let iv = Buffer.from(text.iV, "hex");
  let encryptedText = Buffer.from(text.encryptedData, "hex");
  let decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  const decryptedData = decrypted.toString();
  return decryptedData.split("$$")[0];
}
