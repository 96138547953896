import React, { useEffect, useState } from "react";
import ticket from "../assets/ticket.png";
import { useLocation, useNavigate } from "react-router-dom";
import fetchGemsAndTokens from "../Helper/FetchGemsAndTokens";
import { CONFIG } from "../Helper/config";
import ApiCall from "../Helper/ApiCall";
import { ApiRoutes } from "../Helper/ApiRoutes";
import { HeaderShort, MachineBody, MachineButton } from "../components/common";
import { EarnRewards } from "../static";
import { CoinBalance } from "../components/shared/CoinBalance";
import { BottomNavbar } from "../components/shared/BottomNavbar";
import { Spinner } from "../components/common/Spinner";

function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gems, setGems] = useState(null);
  const state = location.state;
  // console.log(state);

  async function fetchGems() {
    const { resData } = await ApiCall(ApiRoutes.getGemsDetails, "POST", {
      email: state.email,
      tokenId: state.tokenId,
    });
    setGems(resData.data.gems);
    setLoading(false);
  }

  useEffect(() => {
    const isLogedIn = localStorage.getItem("login");
    if (state && state?.smartAccountAddress && isLogedIn) {
      fetchGems();
    } else {
      navigate("/", { replace: true });
      return;
    }
  }, [state]);
  return (
    <>
      {state && !loading ? (
        <>
          <main className="profile">
            <HeaderShort>
              <CoinBalance coins="100000" gems={gems} />
            </HeaderShort>
            <MachineBody heightDecrement={13.625}>
              <div className="profile_body">
                <div className="profile_body_nftTicket">
                  <span className="profile_body_nftTicket_bg">
                    <img src="/nftTicketBG.png" alt="bg" />
                  </span>
                  <div className="profile_body_nftTicket_ticketTitle">
                    <div className="profile_body_nftTicket_ticketTitle_title">
                      SymbiTech 2024
                      <br />
                      Your NFT Ticket
                    </div>
                    <img
                      src="/lync.svg"
                      alt="lync"
                      className="profile_body_nftTicket_ticketTitle_img"
                    />
                  </div>
                  <div className="profile_body_nftTicket_ticketBody">
                    <div>
                      <span className="generatePass_passContainer_passBody_label">Name</span>
                      <p className="generatePass_passContainer_passBody_value">
                        {state?.name || "Your Name"}
                      </p>
                    </div>
                    <div>
                      <span className="generatePass_passContainer_passBody_label">Ticket ID</span>
                      <p className="generatePass_passContainer_passBody_value">
                        {`#${state?.ticketId || "0001"}`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="profile_body_prizeItems">
                  <img src="/prizeItems.png" alt="prize" />
                </div>
              </div>
            </MachineBody>
            <MachineButton>
              <div className="profile_actionButton">
                <button
                  onClick={() => navigate("/earn", { state: { ...state } })}
                  className="profile_actionButton_generateButton"
                >
                  <img src={EarnRewards} alt="Generate_Your_Pass" />
                </button>
              </div>
            </MachineButton>
          </main>
          <BottomNavbar activeId={0} state={state}/>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default Home;
