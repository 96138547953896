import React from "react";
import { headerBarLong, headerBarShort } from "../../static";

export const HeaderBar = ({ children }) => {
  return (
    <header className="headerBar">
      <span className="headerBar_bg">
        <img src={headerBarLong} alt="bg" />
      </span>
      {children}
    </header>
  );
};

export const HeaderShort = ({ children }) => {
  return (
    <header className="headerShort">
      <span className="headerShort_bg">
        <img src={headerBarShort} alt="bg" />
      </span>
      {children}
    </header>
  );
};
