import React from "react";
import { machineBase } from "../../static";

export const MachineButton = ({ children }) => {
  return (
    <section className="machineButton">
      {children}
      <span className="machineButton_bgImg">
        <img src={machineBase} alt="bg" />
      </span>
    </section>
  );
};
