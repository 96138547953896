import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { client, parsers } from "@passwordless-id/webauthn";
import ApiCall from "../Helper/ApiCall";
import { ApiRoutes } from "../Helper/ApiRoutes";
import { HeaderBar, MachineBody, MachineButton } from "../components/common";
import { loginButton, headerStars, registerBanner } from "../static";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!client.isAvailable()) {
      toast.error("WebAuthn is not supported in this browser");
      return;
    }
    if (!email) {
      toast.error("Please Provide Email");
      return;
    }
    setLoading(true);
    try {
      setLoading(true);
      const userAuth = await ApiCall(ApiRoutes.userAuthentication, "POST", {
        email: email,
      });
      if (userAuth.res.ok) {
        const { challenge, credentialId } = userAuth.resData?.data;
        // console.log(challenge);
        const authentication = await client.authenticate([credentialId], challenge, {
          authenticatorType: "auto",
          userVerification: "required",
          timeout: 120000,
        });
        // console.log(authentication);
        const x = parsers.parseAuthentication(authentication);
        // console.log("x", x);
        if (authentication) {
          const userVerifyAuth = await ApiCall(ApiRoutes.userVerifyAuthentication, "POST", {
            authentication,
          });
          if (userVerifyAuth.res.ok && userVerifyAuth.resData.success) {
            const userData = userVerifyAuth.resData?.data?.authenticationParsed;
            toast.success("Success!");
            setTimeout(() => {
              if (userData.smartAccountAddress) {
                localStorage.setItem("login", true);
                navigate("/profile", {
                  state: {
                    name: userData.name,
                    email: userData.email,
                    ticketId: userData.ticketId,
                    credentialId: userData.credentialId,
                    smartAccountAddress: userData.smartAccountAddress,
                    tokenId: userData.tokenId,
                  },
                });
              } else {
                navigate("/generate-pass", {
                  state: {
                    name: userData.name,
                    email: userData.email,
                    ticketId: userData.ticketId,
                    credentialId: userData.credentialId,
                  },
                });
              }
            }, 1500);
          } else {
            toast.error("Login Fail");
          }
        } else {
          toast.error("Please try again!!");
          return;
        }
      } else {
        toast.error(userAuth.resData.message);
        // console.log(userAuth.resData.message);
      }
    } catch (error) {
      toast.error("Please try again!!");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }
  return (
    <main className="register">
      <HeaderBar>
        <h1 className="register_header">
          Login Now!
          <img src={headerStars} alt="stars" className="register_header_stars" />
        </h1>
      </HeaderBar>
      <MachineBody>
        <div className="register_body">
          <div className="register_body_banner">
            <span className="register_body_banner_bannerImg">
              <img src={registerBanner} alt="banner" />
            </span>
            <div className="register_body_banner_bannerText">
              <h2 className="register_body_banner_bannerText_h2">
                Elevate Your Experience:
                <br />
                Join Symbitech, Earn Rewards.
                <img
                  src={headerStars}
                  alt="stars"
                  className="register_body_banner_bannerText_h2_stars"
                />
              </h2>
            </div>
          </div>
          <form className="register_body_form" onSubmit={(e) => handleSubmit(e)}>
            <div className="register_body_form_controlGroup">
              <span className="register_body_form_controlGroup_icon">
                <img style={{ width: "14px", height: "11px" }} src="/message.svg" alt="icon" />
              </span>
              <input
                type="email"
                name="email"
                id="email"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
                placeholder="abc@email.com"
                className="register_body_form_controlGroup_control"
              />
            </div>
          </form>
        </div>
      </MachineBody>
      <MachineButton>
        <div className="register_actionButton">
          <button
            disabled={loading}
            onClick={(e) => handleSubmit(e)}
            className="register_actionButton_registerButton"
          >
            <img src={loading ? "/loginInactive.png" : loginButton} alt="Login" />
          </button>
          <Link to="/register" className="register_actionButton_loginButton">
            Don&apos;t have account?
          </Link>
        </div>
      </MachineButton>
    </main>
  );
}

export default Login;
