import React from "react";
import { useNavigate } from "react-router-dom";

export const CoinBalance = ({ coins, gems }) => {
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.removeItem("login");
    navigate("/", { replace: true });
  };

  return (
    <div className="coinBalance">
      <div className="coinBalance_coinsContainer">
        <div className="coinBalance_coinsContainer_balance">
          <img src="/mainCoin.svg" alt="coins" />
          <span className="coinBalance_coinsContainer_balance_amount">
            {coins}
          </span>
        </div>
        <div className="coinBalance_coinsContainer_balance">
          <img src="/mainDiamond.svg" alt="coins" />
          <span className="coinBalance_coinsContainer_balance_amount">
            {gems}
          </span>
        </div>
      </div>
      <button className="coinBalance_logoutBtn" onClick={() => logoutUser()}>
        Logout
      </button>
    </div>
  );
};
