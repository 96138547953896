import { ethers } from "ethers";
import { Buffer } from "buffer";
import DerToRS from "./DrsToRs";
async function getEncodedSignature(
  signature,
  authenticatorData,
  challenge,
  keyId,
  client,
  clientData,
) {
  const signatureBase64 = signature;
  // console.log("signatureBase64: ", signatureBase64);
  const keyAsBytes = Buffer.from(signatureBase64, "base64");
  // console.log("keyAsBytes: ", keyAsBytes);
  const keyAsHex = keyAsBytes.toString("hex");
  let sigBuf = Buffer.from(keyAsHex, "hex");

  const sigParsed = DerToRS(sigBuf);

  const sig = [
    ethers.BigNumber.from("0x" + sigParsed[0].toString("hex")),
    ethers.BigNumber.from("0x" + sigParsed[1].toString("hex")),
  ];

  // console.log("xSigUint256: ", sig[0].toString());
  // console.log("ySigUint256: ", sig[1].toString());

  const authenticatorDataBase64 = authenticatorData;
  // console.log("authenticatorDataBase64: ", authenticatorDataBase64);

  const keyHash = ethers.utils.solidityKeccak256(["string"], [keyId]);
  // console.log(keyHash);
  const input1 = keyHash;
  const input2 = sig[0];
  const input3 = sig[1];
  const authenticator_Data = Buffer.from(authenticatorData, "base64").toString(
    "hex",
  );
  const input4 = "0x" + authenticator_Data;

  const clientJSONDataBytes = Buffer.from(clientData, "base64").toString("hex");

  const encodedSig = ethers.utils.defaultAbiCoder.encode(
    ["bytes32", "uint256", "uint256", "bytes", "bytes", "string"],
    [input1, input2, input3, input4, "0x" + clientJSONDataBytes, challenge],
  );

  return encodedSig;
}

export default getEncodedSignature;
