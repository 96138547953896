const userRegister = "user/register";
const userVerifyRegistration = "user/verify-registration";
const userAuthentication = "user/authentication";
const userVerifyAuthentication = "user/verify-authentication";
const userGetUserDetails = "user/get-user-details";
const userUpdateContractInformation = "user/update-contract-information";
const userProfile = "user/profile";
const userUpdateSpinCount = "user/update-spin-count";
const userUpdateSpinDetails = "user/update-spin-details";
const getGemsDetails = "user/get-spin-gems-details";
const decreaseGems = "user/decrease-gem-count";
const redeemCoupon = "user/redeem-coupon";
const getWinner = "user/get-winners";
export const ApiRoutes = {
  userRegister,
  userVerifyRegistration,
  userAuthentication,
  userVerifyAuthentication,
  userGetUserDetails,
  userUpdateContractInformation,
  userProfile,
  userUpdateSpinCount,
  userUpdateSpinDetails,
  getGemsDetails,
  decreaseGems,
  redeemCoupon,
  getWinner,
};