import React from "react";
import { machineBGShort } from "../../static";

export const MachineBody = ({ children, heightDecrement = 16.6875 }) => {
  return (
    <section style={{ height: `calc(100dvh - ${heightDecrement}rem)` }} className="machineBody">
      <span className="machineBody_bgImg">
        <img
          style={{ height: `calc(100dvh - ${heightDecrement}rem)` }}
          src={machineBGShort}
          alt="bg"
        />
      </span>
      {children}
    </section>
  );
};

export const MachineBodyLong = ({ children }) => {
  return (
    <section style={{ marginBottom: "0.25rem" }} className="machineBody machineBodyLong">
      <span style={{ height: "100%" }} className="machineBody_bgImg">
        <img style={{ height: "100%" }} src="/machineBGLong.png" alt="bg" />
      </span>
      {children}
    </section>
  );
};
