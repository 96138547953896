import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { SlotMachine, SlotSymbol } from "slot-machine";
import coin from "../assets/coin.svg";
import diamond from "../assets/diamond.svg";
import zero from "../assets/zero.svg";
import one from "../assets/one.svg";
import five from "../assets/five.svg";
import seven from "../assets/seven.svg";
import nine from "../assets/nine.svg";
import lyncOrange from "../assets/lyncOrange.svg";
import whiteDiamond from "../assets/whiteDiamond.svg";
import slotMachineBG from "../assets/slotMachineBg.png";
import { ethers } from "ethers";
import { CONFIG } from "../Helper/config";
import Rewards_ABI from "../abi/Rewards.json";
import getSmartWalletAddress from "../Helper/GetSmartWalletAddress";
import passLessVerification from "../Helper/passLessVerification";
import getEncodedSignature from "../Helper/getEncodedSignatre";
import ACCOUNT_ABI from "../abi/PassKeyAccount.json";
import { decrypt, encryptAPIKey } from "../Helper/encryptMessage";
import ApiCall from "../Helper/ApiCall";
import { ApiRoutes } from "../Helper/ApiRoutes";
import fetchGemsAndTokens from "../Helper/FetchGemsAndTokens";
import getTokenId from "../Helper/GetTokenId";
import slotMachineAudio from "../assets/slotMachineSoundLoop.mp3";
import cheeringAudio from "../assets/cheeringSound.mp3";
import loosingAudio from "../assets/loosingSound.mp3";
import { HeaderShort, MachineBody, MachineButton } from "../components/common";
import { CoinBalance } from "../components/shared/CoinBalance";
import { generatePassButton } from "../static";
import { BottomNavbar } from "../components/shared/BottomNavbar";
import getRendomNonce from "../Helper/getRendomNonce";
import { Spinner } from "../components/common/Spinner";
import checkWinner from "../Helper/CheckWinner";
import prize1 from "../assets/prize-1.svg";
import prize2 from "../assets/prize-2.svg";
import prize3 from "../assets/prize-3.svg";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
const slotMachineSound = new Audio(slotMachineAudio);
const cheeringSound = new Audio(cheeringAudio);
const loosingSound = new Audio(loosingAudio);

const zeroSymbol = new SlotSymbol("zeroSymbol", {
  display: zero,
  points: 2,
  weight: 10,
});

const oneSymbol = new SlotSymbol("oneSymbol", {
  display: one,
  points: 1,
  weight: 10,
});

const fiveSymbol = new SlotSymbol("fiveSymbol", {
  display: five,
  points: 5,
  weight: 10,
});

const sevenSymbol = new SlotSymbol("sevenSymbol", {
  display: seven,
  points: 7,
  weight: 10,
});

const nineSymbol = new SlotSymbol("nineSymbol", {
  display: nine,
  points: 9,
  weight: 10,
});

const coinSymbol = new SlotSymbol("coinSymbol", {
  display: coin,
  points: 20,
  weight: 10,
});

const lyncSymbol = new SlotSymbol("lyncSymbol", {
  display: lyncOrange,
  points: 30,
  weight: 10,
});

const diamondSymbol = new SlotSymbol("diamondSymbol", {
  display: diamond,
  points: 40,
  weight: 10,
});

const whiteDiamondSymbol = new SlotSymbol("whiteDiamondSymbol", {
  display: whiteDiamond,
  points: 50,
  weight: 10,
});

function Earn() {
  const machine = new SlotMachine(3, [
    zeroSymbol,
    oneSymbol,
    fiveSymbol,
    sevenSymbol,
    nineSymbol,
    coinSymbol,
    lyncSymbol,
    diamondSymbol,
    whiteDiamondSymbol,
  ]);

  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeStamp, setTimeStamp] = useState(null);

  const state = location?.state;
  // console.log(state);
  const tokenID = state?.tokenId;

  const [lotteryArray, setLotteryArray] = useState([
    [zero, coin, one],
    [diamond, seven, lyncOrange],
    [five, whiteDiamond, nine],
  ]);
  const [runningSlotSpeed, setRunningSlotSpeed] = useState(0);

  async function getUerData() {
    try {
      const userProfile = await ApiCall(ApiRoutes.userProfile, "POST", {
        email: state?.email,
      });
      if (userProfile.res.ok) {
        setUserData({
          ...userProfile.resData.data,
        });
      } else {
        toast.error(userProfile.resData.message);
        navigate("/");
        return;
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }
  const RedeemImages = {
    150: prize1,
    180: prize2,
    200: prize3,
  };

  async function spin(luckyNumbers, passLessVerification) {
    try {
      const luckyNumberJson = JSON.stringify({
        first: luckyNumbers[0],
        second: luckyNumbers[1],
        third: luckyNumbers[2],
      });
      const luckyNumber = await encryptAPIKey(luckyNumberJson);
      const spinCount = await ApiCall(ApiRoutes.userUpdateSpinCount, "POST", {
        email: state?.email,
        smartAccountAddress: state?.smartAccountAddress,
        luckyNumber: luckyNumber,
      });
      const gemsToBeAdded = decrypt(spinCount.resData.data.gemsAdded);
      if (spinCount.res.ok && spinCount.resData.success) {
        try {
          const smartWalletData = await getSmartWalletAddress(state?.email);
          if (smartWalletData) {
            const { signature, authenticatorData, challenge, client, clientData } =
              passLessVerification;
            const encodedSig = await getEncodedSignature(
              signature,
              authenticatorData,
              challenge,
              smartWalletData.keyId,
              client,
              clientData
            );
            // console.log(encodedSig);
            const initCode = "0x";
            const rewardsNonce = getRendomNonce();
            const signingAccount = new ethers.Wallet(process.env.REACT_APP_PVT_KEY);
            // console.log(rewardsNonce, signingAccount);
            const rewardContract = new ethers.Contract(
              CONFIG.Rewards,
              Rewards_ABI,
              smartWalletData.provider
            );
            const encodedData = ethers.utils.solidityPack(
              ["address", "uint256", "uint256"],
              [CONFIG.Rewards, CONFIG.ChainId, rewardsNonce]
            );
            const hash = ethers.utils.keccak256(encodedData);
            // console.log(hash);
            const rewardsSign = await signingAccount.signMessage(ethers.utils.arrayify(hash));

            const rewardNFTData = rewardContract.interface.encodeFunctionData("incGems", [
              tokenID,
              String(gemsToBeAdded),
              rewardsSign,
              rewardsNonce,
            ]);
            const AccountContract = new ethers.Contract(
              state.smartAccountAddress,
              ACCOUNT_ABI,
              smartWalletData.provider
            );
            const encodedCall = AccountContract.interface.encodeFunctionData("execute", [
              CONFIG.Rewards,
              ethers.BigNumber.from(0),
              rewardNFTData,
            ]);
            const currentNonce = await AccountContract.getNonce();
            // console.log(currentNonce);
            const optionsPaymaster = {
              method: "POST",
              headers: {
                accept: "application/json",
                "content-type": "application/json",
              },
              body: JSON.stringify({
                id: 1,
                jsonrpc: "2.0",
                method: "alchemy_requestGasAndPaymasterAndData",
                params: [
                  {
                    policyId: process.env.REACT_APP_ALCHEMY_POLICY_ID,
                    entryPoint: CONFIG.entryPoint,
                    dummySignature: encodedSig,
                    userOperation: {
                      sender: state.smartAccountAddress,
                      nonce: currentNonce._hex,
                      initCode: initCode,
                      callData: encodedCall,
                    },
                  },
                ],
              }),
            };
            const respaymaster = await fetch(CONFIG.rpcUrl, optionsPaymaster);
            if (respaymaster.ok) {
              const respaymasterData = await respaymaster.json();
              if (respaymasterData.result) {
                setRunningSlotSpeed(400);
                // console.log(respaymaster);
                // console.log("respaymasterData: ", respaymasterData);
                let op = {
                  sender: state.smartAccountAddress,
                  nonce: currentNonce._hex,
                  initCode: initCode,
                  callData: encodedCall,
                  maxFeePerGas: respaymasterData.result.maxFeePerGas,
                  maxPriorityFeePerGas: respaymasterData.result.maxPriorityFeePerGas,
                  signature: encodedSig,
                };

                op.callGasLimit = respaymasterData.result.callGasLimit;
                op.verificationGasLimit = respaymasterData.result.verificationGasLimit;
                op.preVerificationGas = respaymasterData.result.preVerificationGas;
                op.paymasterAndData = respaymasterData.result.paymasterAndData;
                // console.log("op: ", op);
                const options1 = {
                  method: "POST",
                  headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    id: 1,
                    jsonrpc: "2.0",
                    method: "eth_sendUserOperation",
                    params: [op, CONFIG.entryPoint],
                  }),
                };

                const alachemyresp = await fetch(CONFIG.rpcUrl, options1);
                const alachemyrespData = await alachemyresp.json();
                // console.log("alachemyrespData: ", alachemyrespData);
                if (alachemyrespData.result && alachemyresp.ok) {
                  const updateSpinDetails = await ApiCall(ApiRoutes.userUpdateSpinDetails, "POST", {
                    email: state.email,
                    gems: gemsToBeAdded,
                  });
                  // console.log(updateSpinDetails.res, updateSpinDetails.resData);
                  setUserData((prv) => ({
                    ...prv,
                    gems: updateSpinDetails?.resData?.data?.gems,
                    spinDetails: {
                      ...prv.spinDetails,
                      spinCount: updateSpinDetails.resData.data.spinCount,
                    },
                  }));
                  toast.success(`YOU WON ${gemsToBeAdded} GEMS`);
                  setRunningSlotSpeed(0);
                  slotMachineSound.pause();
                  slotMachineSound.currentTime = 0;
                  cheeringSound.play();
                  cheeringSound.currentTime = 2.75;
                } else {
                  toast.error("Please try again!!");
                  setRunningSlotSpeed(0);
                  slotMachineSound.pause();
                  loosingSound.play();
                  slotMachineSound.currentTime = 0;
                }
              } else {
                // console.log(respaymasterData);
                toast.error(respaymasterData.error.message);
                setRunningSlotSpeed(0);
                slotMachineSound.pause();
                loosingSound.play();
                slotMachineSound.currentTime = 0;
              }
            } else {
              // console.log("Error from alchemy paymaster API");
              toast.error("Please try again!!");
              setRunningSlotSpeed(0);
              slotMachineSound.pause();
              loosingSound.play();
              slotMachineSound.currentTime = 0;
            }
          }
          // console.log(smartWalletData);
        } catch (error) {
          // console.log(error);
          setRunningSlotSpeed(0);
          slotMachineSound.pause();
          loosingSound.play();
          slotMachineSound.currentTime = 0;
        }
      } else {
        toast.error(spinCount.resData.message);
        setRunningSlotSpeed(0);
        slotMachineSound.pause();
        loosingSound.play();
        slotMachineSound.currentTime = 0;
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      setRunningSlotSpeed(0);
      slotMachineSound.pause();
      loosingSound.play();
      slotMachineSound.currentTime = 0;
    }
  }

  const runSlotMachine = async () => {
    try {
      slotMachineSound.play();
      setRunningSlotSpeed(250);
      const passLessVerificationData = await passLessVerification(state?.email);
      const results = machine.play();
      loosingSound.currentTime = 0;
      slotMachineSound.loop = true;
      setLotteryArray([
        [
          results.lines[0].symbols[0].display,
          results.lines[0].symbols[1].display,
          results.lines[0].symbols[2].display,
        ],
        [
          results.lines[1].symbols[0].display,
          results.lines[1].symbols[1].display,
          results.lines[1].symbols[2].display,
        ],
        [
          results.lines[2].symbols[0].display,
          results.lines[2].symbols[1].display,
          results.lines[2].symbols[2].display,
        ],
      ]);

      await spin(
        [
          results.lines[0].symbols[1].points,
          results.lines[1].symbols[1].points,
          results.lines[2].symbols[1].points,
        ],
        passLessVerificationData
      );
    } catch (error) {
      // console.log(error);
      slotMachineSound.pause();
      setRunningSlotSpeed(0);
    }
  };

  const targetDate = dayjs("2023-09-22T19:00:00");
  dayjs.extend(duration);

  const getTimestamp = () => {
    const interval = setInterval(() => {
      const currentDate = dayjs();
      const remainingTime = targetDate.diff(currentDate, "ms");

      if (remainingTime <= 0) {
        clearInterval(interval);
        setTimeStamp(null);
        return;
      }

      const remainingDuration = dayjs.duration(remainingTime);

      const days = remainingDuration.days();
      const hours = remainingDuration.hours();
      const minutes = remainingDuration.minutes();

      setTimeStamp({
        days: days,
        hours: hours,
        minutes: minutes,
      });
    }, 60000);
  };

  getTimestamp();

  useEffect(() => {
    const isLogedIn = localStorage.getItem("login");

    if (state && state?.smartAccountAddress && isLogedIn) {
      getUerData();
    } else {
      navigate("/", { replace: true });
      return;
    }
  }, [state]);
  // console.log(userData);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <main className="profile earn">
            <HeaderShort>
              <CoinBalance coins="100000" gems={userData?.gems} />
            </HeaderShort>
            <MachineBody heightDecrement={13.625}>
              <div className="profile_body earn_body">
                <div style={{ marginTop: "0" }} className="profile_body_prizeItems">
                  <img src="/prizeItemsShort.png" alt="prize" />
                  <div className="earnpage-marqueeContainer">
                    <div>
                      <span>Every spin, blockchain-pinned!</span>
                      <span>Let&apos;s LYNC</span>
                      <span>India: Ascending to Web3 Supremacy!</span>
                      <span>Every spin, blockchain-pinned!</span>
                      <span>Let&apos;s LYNC</span>
                      <span>India: Ascending to Web3 Supremacy!</span>
                      <span>Every spin, blockchain-pinned!</span>
                      <span>Let&apos;s LYNC</span>
                      <span>India: Ascending to Web3 Supremacy!</span>
                      <span>Every spin, blockchain-pinned!</span>
                      <span>Let&apos;s LYNC</span>
                      <span>India: Ascending to Web3 Supremacy!</span>
                      <span>Every spin, blockchain-pinned!</span>
                      <span>Let&apos;s LYNC</span>
                      <span>India: Ascending to Web3 Supremacy!</span>
                    </div>
                  </div>
                </div>
                <div className="slotMachine-slotLine">
                  <div>
                    {lotteryArray.map((lines, outerIdx) => {
                      return (
                        <div
                          key={outerIdx}
                          className={`slotMachine-slotItems ${
                            runningSlotSpeed === 250
                              ? "runningSlots faster"
                              : runningSlotSpeed === 400
                              ? "runningSlots slower"
                              : ""
                          }`}
                        >
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                          {lines.map((symbols, innerIdx) => {
                            return (
                              <span key={outerIdx + innerIdx} className="slotMachine-itemImages">
                                <img src={symbols} alt="reward" />
                              </span>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <span className="earnpage-spinCount">
                    {userData?.spinDetails?.spinCount || "00"}/5 spins left
                  </span>
                  <div className="slotMachine-slotBg">
                    <img src="/spinnerBG.png" alt="bg" />
                  </div>
                </div>
              </div>
            </MachineBody>
            <MachineButton>
              <div className="profile_actionButton">
                <button
                  disabled={runningSlotSpeed !== 0 || userData?.spinDetails?.spinCount <= 0}
                  onClick={() => runSlotMachine()}
                  className="profile_actionButton_generateButton"
                >
                  <img
                    src={
                      runningSlotSpeed !== 0 || userData?.spinDetails?.spinCount <= 0
                        ? "/spinButtonSpinning.png"
                        : "/spinButtonActive.png"
                    }
                    alt="Spin"
                  />
                </button>
              </div>
            </MachineButton>
            <div className="border-rect"></div>
            <ul className="earnpage-rewardsList">
              {userData && userData?.redeemDetails.length > 0 ? (
                <>
                  {userData?.redeemDetails.map((data, index) => {
                    return (
                      <li
                        className="earnpage-rewardsListItem"
                        key={index}
                        onClick={() =>
                          checkWinner(
                            state.tokenId,
                            data,
                            state?.email,
                            index,
                            setUserData,
                            userData?.redeemDetails
                          )
                        }
                      >
                        <span className="rewardsListItem-rewardImage">
                          <img src={RedeemImages[data]} alt="prize" />
                        </span>
                        <span className="rewardsListItem-rewardValue">
                          {timeStamp === null ? null : (
                            <span className="rewardValueTop">Reveal reward in</span>
                          )}
                          <span className="rewardValueBottom">
                            {timeStamp === null
                              ? "Reveal reward NOW"
                              : `${timeStamp.days}day ${timeStamp.hours}hours ${timeStamp.minutes}mins`}
                          </span>
                        </span>
                      </li>
                    );
                  })}
                </>
              ) : (
                <>
                  <li
                    className="earnpage-rewardsListItem"
                    onClick={() => navigate("/rewards", { state: state })}
                  >
                    <span className="rewardsListItem-rewardImage">
                      <img src="/coinsImage.png" alt="prize" />
                    </span>
                    <span className="rewardsListItem-rewardValue">
                      <span className="rewardValueTop">You don&apos;t have any coupon</span>
                      <span className="rewardValueBottom">Click to redeem</span>
                    </span>
                  </li>
                  <li
                    className="earnpage-rewardsListItem"
                    onClick={() => navigate("/rewards", { state: state })}
                  >
                    <span className="rewardsListItem-rewardImage">
                      <img src="/coinsImage.png" alt="prize" />
                    </span>
                    <span className="rewardsListItem-rewardValue">
                      <span className="rewardValueTop">You don&apos;t have any coupon</span>
                      <span className="rewardValueBottom">Click to redeem</span>
                    </span>
                  </li>
                  <li
                    className="earnpage-rewardsListItem"
                    onClick={() => navigate("/rewards", { state: state })}
                  >
                    <span className="rewardsListItem-rewardImage">
                      <img src="/coinsImage.png" alt="prize" />
                    </span>
                    <span className="rewardsListItem-rewardValue">
                      <span className="rewardValueTop">You don&apos;t have any coupon</span>
                      <span className="rewardValueBottom">Click to redeem</span>
                    </span>
                  </li>
                </>
              )}
            </ul>
            <div className="border-rect noTranslate"></div>
          </main>
          <BottomNavbar activeId={2} state={state} />
        </>
      )}
    </>
  );
}

export default Earn;
