import toast from "react-hot-toast";
import ApiCall from "./ApiCall";
import { ApiRoutes } from "./ApiRoutes";
import { CONFIG } from "./config";

const winnerMessage = {
  0: "Sorry! You are not a winner!!",
  1: "Congratulation! You have won!!",
  2: "You have already won that prize",
};
async function checkWinner(tokenId, coupon, email, index, setUserData, array) {
  // return toast.error("Result will be declared soon!!");
  const couponId = CONFIG.couponIdOBj[coupon];

  const getWinner = await ApiCall(ApiRoutes.getWinner, "POST", {});
  if (getWinner.res.ok) {
    const winnerData = getWinner.resData.data;
    if (winnerData && winnerData[couponId] && winnerData[couponId].winnerId) {
      console.log(winnerData);
      const obj = {};
      //   Todo use for loop instead of map
      array.map((item, index) => {
        if (obj[item] === undefined) {
          obj[item] = index;
        }
      });
      console.log(obj, winnerData[couponId].winnerId);
      if (winnerData[couponId].winnerId == tokenId) {
        console.log("if");
        console.log(index, obj[coupon]);
        if (index != obj[coupon]) {
          const redeem = await ApiCall(ApiRoutes.redeemCoupon, "POST", {
            email,
            index,
          });
          toast.success(winnerMessage[2]);
          setUserData((prv) => ({
            ...prv,
            redeemDetails: redeem.resData.data.redeemDetails,
          }));
          return;
        }
        toast.success(winnerMessage[1]);
      } else {
        const redeem = await ApiCall(ApiRoutes.redeemCoupon, "POST", {
          email,
          index,
        });
        setUserData((prv) => ({
          ...prv,
          redeemDetails: redeem.resData.data.redeemDetails,
        }));
        toast.error(winnerMessage[0]);
      }
    } else {
      toast.error("Winner is not declared yet ");
    }
  } else {
    toast.error("Something went wrong in checkWinner function");
  }
}
export default checkWinner;
