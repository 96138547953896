import { ethers } from "ethers";
import { Buffer } from "buffer";
const getXandY = async (pubKey) => {
  const keyAsTxt = pubKey;

  const keyAsBytes = Buffer.from(keyAsTxt, "base64");
  const keySpec = new Uint8Array(keyAsBytes);
  const algo = { name: "ECDSA", namedCurve: "P-256" };
  const publicKey = await window.crypto.subtle.importKey(
    "spki",
    keySpec,
    algo,
    true,
    ["verify"]
  );
  // convert public key to hex string
  const publicKeyHex = Buffer.from(
    await window.crypto.subtle.exportKey("raw", publicKey)
  ).toString("hex");
  // console.log("publicKeyHex: ", publicKeyHex);

  // remove the first byte (0x04) from the hex string
  const publicKeyHexWithoutPrefix = publicKeyHex.slice(2);
  // get x and y coordinates from the hex string
  const x = publicKeyHexWithoutPrefix.slice(0, 64);
  const y = publicKeyHexWithoutPrefix.slice(64);

  // convert x and y coordinates to big numbers
  const xBN = ethers.BigNumber.from("0x" + x);
  const yBN = ethers.BigNumber.from("0x" + y);

  // convert x and y coordinates to uint256 solidity type
  // console.log("xUint256: ", xBN.toString());
  // console.log("yUint256: ", yBN.toString());
  return { x: xBN.toString(), y: yBN.toString() };
};
export default getXandY;
