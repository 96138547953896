import React from "react";
import { Link } from "react-router-dom";

export const BottomNavbar = ({ activeId = 0, state }) => {
  return (
    <nav className="bottomNavbar">
      <ul className="bottomNavbar_navigationActions">
        <li>
          <Link
            to="/profile"
            className={`bottomNavbar_navigationActions_actionBtns ${
              activeId === 0 ? "active" : ""
            }`}
            state={state}
          >
            <span className="bottomNavbar_navigationActions_actionBtns_icons">
              <img
                src={
                  activeId === 0 ? "/profileActive.svg" : "/profileMuted.svg"
                }
                alt="profile"
              />
            </span>
            <span className="bottomNavbar_navigationActions_actionBtns_name">
              Profile
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/rewards"
            className={`bottomNavbar_navigationActions_actionBtns centerActionButton ${
              activeId === 1 ? "active" : ""
            }`}
            state={state}
          >
            <span className="bottomNavbar_navigationActions_actionBtns_icons">
              <img
                src={
                  activeId === 1 ? "/rewardsActive.svg" : "/rewardsMuted.svg"
                }
                alt="redeem"
              />
            </span>
            <span className="bottomNavbar_navigationActions_actionBtns_name">
              Rewards
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/earn"
            className={`bottomNavbar_navigationActions_actionBtns ${
              activeId === 2 ? "active" : ""
            }`}
            state={state}
          >
            <span className="bottomNavbar_navigationActions_actionBtns_icons">
              <img
                src={activeId === 2 ? "/earnActive.svg" : "/earnMuted.svg"}
                alt=""
              />
            </span>
            <span className="bottomNavbar_navigationActions_actionBtns_name">
              Earn
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
