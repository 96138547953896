export const initialState = {
  provider: null,
  factoryContract: null,
  smartWalletAddress: null,
  isDeploying: false,
  isLoading: true,
  publicKey: null,
  keyId: null,
  explore: null,
  publicX: null,
  publicY: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        isLoading: false,
        provider: action.payload.provider,
        factoryContract: action.payload.factory,
        smartWalletAddress: action.payload.smartWalletAddress,
        publicKey: action.payload.publicKey,
        keyId: action.payload.keyId,
        publicX: action.payload.publicX,
        publicY: action.payload.publicY,
      };
    case "SET_LOADING_FALSE":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_DEPLOYING_TRUE":
      return {
        ...state,
        isDeploying: true,
      };
    case "SET_DEPLOYING_FALSE":
      return {
        ...state,
        isDeploying: false,
      };
    case "SET_EXPLORE":
      return {
        ...state,
        explore: action.payload.explore,
      };
    default:
      return state;
  }
};
