import { client, parsers } from "@passwordless-id/webauthn";
import { encryptAPIKey } from "./encryptMessage";
import ApiCall from "./ApiCall";
import { ApiRoutes } from "./ApiRoutes";
import toast from "react-hot-toast";

async function passLessVerification(email) {
  try {
    const userAuthentication = await ApiCall(
      ApiRoutes.userAuthentication,
      "POST",
      {
        email,
      }
    );

    if (userAuthentication.res.ok) {
      const { challenge, credentialId } = userAuthentication.resData?.data;
      // console.log(challenge);
      const authentication = await client.authenticate(
        [credentialId],
        challenge,
        {
          authenticatorType: "auto",
          userVerification: "required",
          timeout: 120000,
        }
      );
      // console.log(authentication);
      const x = parsers.parseAuthentication(authentication);
      // console.log("x", x);
      if (authentication) {
        const verify = await ApiCall(
          ApiRoutes.userVerifyAuthentication,
          "POST",
          {
            authentication,
          }
        );

        if (verify.res.ok) {
          return {
            signature: authentication.signature,
            authenticatorData: authentication.authenticatorData,
            challenge: challenge,
            client: x.client,
            clientData: authentication.clientData,
            userData: {
              email: verify.resData.data.authenticationParsed.email,
              ticketId: verify.resData.data.authenticationParsed.ticketId,
              name: verify.resData.data.authenticationParsed.name,
            },
          };
        } else {
          toast.error("User Verification failed");
          throw Error("User Verification failed");
        }
      } else {
        toast.error("User Authentication failed");

        throw Error("User Authentication failed");
      }
    } else {
      toast.error("User not found");

      // console.log(userAuthentication.resData.message);
      throw Error(userAuthentication.resData.message);
    }
  } catch (error) {
    toast.error("Please refresh the page and try again!!");
    console.log(error);
    throw Error(error);
  }
}

export default passLessVerification;
