import { encryptAPIKey } from "./encryptMessage";

async function ApiCall(url, method, data) {
  try {
    const encryptApiKey = await encryptAPIKey();
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": encryptApiKey,
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return { res, resData: json };
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export default ApiCall;
