import { ethers } from "ethers";
import { CONFIG } from "./config";

async function getTokenId(hex) {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify({
      id: 1,
      jsonrpc: "2.0",
      method: "eth_getUserOperationReceipt",
      params: [`${hex}`],
    }),
  };
  const chechTransactionRes = await fetch(CONFIG.rpcUrl, options);
  const chechTransactionResData = await chechTransactionRes.json();
  // console.log(chechTransactionResData);
  if (chechTransactionResData.result) {
    let abi1155TransferSingle = [
      "event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)",
    ];
    let iface1155TransferSingleEvent = new ethers.utils.Interface(
      abi1155TransferSingle
    );
    const parsedLog = iface1155TransferSingleEvent.parseLog(
      chechTransactionResData.result.logs[6]
    );
    const { id } = parsedLog.args;
    // console.log(id.toString())
    return id.toString();
  } else {
    return null;
  }
}
export default getTokenId;
