import { ethers } from "ethers";
import { CONFIG } from "../Helper/config";
import ACCOUNT_ABI from "../abi/PassKeyAccount.json";
import NFT_ABI from "../abi/LyncPasskeyNFT.json";
import passLessVerification from "../Helper/passLessVerification";
import getEncodedSignature from "../Helper/getEncodedSignatre";
import toast from "react-hot-toast";
import { encryptAPIKey } from "./encryptMessage";
import ApiCall from "./ApiCall";
import { ApiRoutes } from "./ApiRoutes";
import getTokenId from "./GetTokenId";
import getRendomNonce from "./getRendomNonce";

export default async function deplySmartAccount(
  data,
  dispatch,
  email,
  navigate
) {
  const {
    isLoading,
    isDeploying,
    smartWalletAddress,
    publicKey,
    keyId,
    provider,
    factoryContract,
    explore,
    publicX,
    publicY,
  } = data;
  dispatch({ type: "SET_DEPLOYING_TRUE" });
  // check if contract exists on smartWalletAddress using ethers
  const exists = await provider.getCode(smartWalletAddress);
  const AccountContract = new ethers.Contract(
    CONFIG.ImplementationContractAddress,
    ACCOUNT_ABI,
    provider
  );
  // console.log(AccountContract);
  const NFTContract = new ethers.Contract(
    CONFIG.LyncNFTAddress,
    NFT_ABI,
    provider
  );

  // console.log(NFTContract);
  // console.log(exists);

  if (exists === "0x") {
    // if (true) {
    try {
      const {
        signature,
        authenticatorData,
        challenge,
        client,
        clientData,
        userData,
      } = await passLessVerification(email);
      const encodedSig = await getEncodedSignature(
        signature,
        authenticatorData,
        challenge,
        keyId,
        client,
        clientData
      );

      let initCode = ethers.utils.hexConcat([
        CONFIG.factory,
        factoryContract.interface.encodeFunctionData("createAccount", [
          String(CONFIG.fixedNonce),
          keyId,
          publicX,
          publicY,
        ]),
      ]);
      // console.log("initCode: ", initCode);
      const mintNonce = getRendomNonce();
      const signingAccount = new ethers.Wallet(process.env.REACT_APP_PVT_KEY);

      const hash = await NFTContract.getHash(mintNonce);
      const mintSig = await signingAccount.signMessage(
        ethers.utils.arrayify(hash)
      );

      const mintNFTData = NFTContract.interface.encodeFunctionData("safeMint", [
        smartWalletAddress,
        mintSig,
        mintNonce,
      ]);
      const encodedCall = AccountContract.interface.encodeFunctionData(
        "execute",
        [CONFIG.LyncNFTAddress, ethers.BigNumber.from(0), mintNFTData]
      );
      const optionsPaymaster = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          id: 1,
          jsonrpc: "2.0",
          method: "alchemy_requestGasAndPaymasterAndData",
          params: [
            {
              policyId: process.env.REACT_APP_ALCHEMY_POLICY_ID,
              entryPoint: CONFIG.entryPoint,
              dummySignature: encodedSig,
              userOperation: {
                sender: smartWalletAddress,
                nonce: "0x0",
                initCode: initCode,
                callData: encodedCall,
              },
            },
          ],
        }),
      };

      const respaymaster = await fetch(CONFIG.rpcUrl, optionsPaymaster);
      const respaymasterData = await respaymaster.json();
      // console.log("respaymasterData: ", respaymasterData);

      let op = {
        sender: smartWalletAddress,
        nonce: "0x0",
        initCode: initCode,
        callData: encodedCall,
        maxFeePerGas: respaymasterData.result.maxFeePerGas,
        maxPriorityFeePerGas: respaymasterData.result.maxPriorityFeePerGas,
        signature: encodedSig,
      };

      op.callGasLimit = respaymasterData.result.callGasLimit;
      op.verificationGasLimit = respaymasterData.result.verificationGasLimit;
      op.preVerificationGas = respaymasterData.result.preVerificationGas;
      op.paymasterAndData = respaymasterData.result.paymasterAndData;
      // console.log("op: ", op);
      const options1 = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          id: 1,
          jsonrpc: "2.0",
          method: "eth_sendUserOperation",
          params: [op, CONFIG.entryPoint],
        }),
      };

      const alachemyresp = await fetch(CONFIG.rpcUrl, options1);
      const alachemyrespData = await alachemyresp.json();
      // console.log("alachemyrespData: ", alachemyrespData);
      if (alachemyrespData.result) {
        let tokenId;
        const interval = setInterval(async () => {
          tokenId = await getTokenId(alachemyrespData.result);
          // console.log("tokenId", tokenId);
          if (tokenId) {
            clearInterval(interval);
            const updateContract = await ApiCall(
              ApiRoutes.userUpdateContractInformation,
              "POST",
              {
                email: email,
                contractAddress: smartWalletAddress,
                tokenId: tokenId,
              }
            );

            if (updateContract.res.ok) {
              // console.log(updateContract.resData.message);
            } else {
              // console.log(updateContract.resData);
            }
            localStorage.setItem("login", true);
            toast.success("Pass Generated Successfully");
            dispatch({
              type: "SET_EXPLORE",
              payload: { explore: alachemyrespData.result },
            });
            dispatch({ type: "SET_DEPLOYING_FALSE" });
            setTimeout(() => {
              navigate("/profile", {
                state: {
                  name: userData.name,
                  email: userData.email,
                  ticketId: userData.ticketId,
                  smartAccountAddress: smartWalletAddress,
                  tokenId: tokenId,
                },
                replace: true,
              });
            }, 1000);
          }
        }, 3000);
      } else {
        toast.error("Please try again!!");
        dispatch({ type: "SET_DEPLOYING_FALSE" });
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      dispatch({ type: "SET_DEPLOYING_FALSE" });
    }
  } else {
    toast.error("Pass Already Generated");
    dispatch({ type: "SET_DEPLOYING_FALSE" });
    return;
  }

  // setDeploying(false);
}
