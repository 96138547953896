import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import Earn from "./Pages/Earn";
import GeneratePass from "./Pages/GeneratePass";
import Login from "./Pages/Login";
import Rewards from "./Pages/Rewards";
import Default from "./Pages/Default";
import ReactGA from 'react-ga';

function App() {

  ReactGA.initialize("G-1TN33FQZR0");

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Default />} />
        <Route path="/profile" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/login" element={<Login />} />
        <Route path="/generate-pass" element={<GeneratePass />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
